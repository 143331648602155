var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "previred" },
    [
      _vm.isAcepta
        ? _c("PreviredAcepta", {
            attrs: {
              rut: _vm.upperClientRut,
              "executive-rut": _vm.upperExecutiveRut,
              "response-type": _vm.responseType,
              "dummy-mode": _vm.dummyMode,
              "kong-token": _vm.kongToken,
              "kong-client-id": _vm.innerClientId,
              "kong-client-secret": _vm.innerClientSecret,
              environment: _vm.environment,
              previredAPI: _vm.previredAPI,
              "trace-client": _vm.traceService("Validation.Acepta"),
              "trace-id": _vm.traceId,
              "channel-key": _vm.channelKey,
            },
            on: {
              previredAcepta: function ($event) {
                return _vm.$emit("previred", $event)
              },
            },
          })
        : _vm._e(),
      _vm.isKBA
        ? _c("PreviredEquifax", {
            attrs: {
              rut: _vm.upperClientRut,
              "response-type": _vm.responseType,
              provider: _vm.authMethod,
              "time-limit-mode": _vm.timeLimitMode,
              color: _vm.color,
              "dummy-mode": _vm.dummyMode,
              "kong-token": _vm.kongToken,
              "kong-client-id": _vm.innerClientId,
              "kong-client-secret": _vm.innerClientSecret,
              environment: _vm.environment,
              previredAPI: _vm.previredAPI,
              "trace-client": _vm.traceService("Validation.Equifax"),
              "trace-id": _vm.traceId,
              "channel-key": _vm.channelKey,
            },
            on: {
              auth: function ($event) {
                return _vm.$emit("auth", $event)
              },
              previredEquifax: function ($event) {
                return _vm.$emit("previred", $event)
              },
              goBack: function ($event) {
                return _vm.$emit("goBack")
              },
            },
          })
        : _vm._e(),
      _vm.isRemoteAssisted
        ? _c("PreviredRemoteAssisted", {
            attrs: {
              rut: _vm.upperClientRut,
              "response-type": _vm.responseType,
              color: _vm.color,
              executiveRut: _vm.upperExecutiveRut,
              authMethod: _vm.isRemoteAssistedFacial && _vm.authMethod,
              "dummy-mode": _vm.dummyMode,
              "kong-token": _vm.kongToken,
              "kong-client-id": _vm.innerClientId,
              "kong-client-secret": _vm.innerClientSecret,
              environment: _vm.environment,
              previredService: _vm.previredService,
              "trace-service": _vm.traceService("Validation.Remote"),
              "trace-id": _vm.traceId,
              "channel-key": _vm.channelKey,
              "hide-code": _vm.hideCode,
            },
            on: {
              previredRemoteAssisted: function ($event) {
                return _vm.$emit("previred", $event)
              },
              onCodeGenerated: function ($event) {
                return _vm.$emit("onCodeGenerated", $event)
              },
              goBack: function ($event) {
                return _vm.$emit("goBack")
              },
            },
          })
        : _vm._e(),
      _vm.isCICUERemoteAssisted
        ? _c("PreviredCICUERemoteAssisted", {
            attrs: {
              rut: _vm.upperClientRut,
              "response-type": _vm.responseType,
              color: _vm.color,
              executiveRut: _vm.upperExecutiveRut,
              "auth-method": _vm.isCICUERemoteAssisted && _vm.authMethod,
              "dummy-mode": _vm.dummyMode,
              "kong-token": _vm.kongToken,
              "kong-client-id": _vm.innerClientId,
              "kong-client-secret": _vm.innerClientSecret,
              environment: _vm.environment,
              previredService: _vm.previredService,
              "trace-service": _vm.traceService("Validation.Remote"),
              "trace-id": _vm.traceId,
              "channel-key": _vm.channelKey,
              "client-email": _vm.clientEmail,
              "timeout-ci": _vm.timeoutCi,
              "timeout-cue": _vm.timeoutCue,
              "strict-name-validation": _vm.strictNameValidation,
              "hide-code": _vm.hideCode,
            },
            on: {
              previredCICUERemoteAssisted: function ($event) {
                return _vm.$emit("previred", $event)
              },
              onCodeGenerated: function ($event) {
                return _vm.$emit("onCodeGenerated", $event)
              },
              goBack: function ($event) {
                return _vm.$emit("goBack")
              },
            },
          })
        : _vm._e(),
      _vm.isFacialRecognition
        ? _c("PreviredFacialRecognition", {
            attrs: {
              rut: _vm.upperClientRut,
              "response-type": _vm.responseType,
              provider: _vm.authMethod,
              "time-limit-mode": _vm.timeLimitMode,
              color: _vm.color,
              "dummy-mode": _vm.dummyMode,
              "kong-token": _vm.kongToken,
              "kong-client-id": _vm.innerClientId,
              "kong-client-secret": _vm.innerClientSecret,
              environment: _vm.environment,
              previredAPI: _vm.previredAPI,
              previredService: _vm.previredService,
              "trace-client": _vm.traceService("Validation.FacialRecognition"),
              "trace-id": _vm.traceId,
              "channel-key": _vm.channelKey,
            },
            on: {
              auth: function ($event) {
                return _vm.$emit("auth", $event)
              },
              previredFacialRecognition: function ($event) {
                return _vm.$emit("previred", $event)
              },
              goBack: function ($event) {
                return _vm.$emit("goBack")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }