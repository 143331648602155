import PreviredAPIClientMock from "./previred-api-mock";

const PREVIRED_MODE = {
  DATA: "DATA",
  TOKEN: "TOKEN"
};

const VALIDATION = {
  ACEPTA_BIOMETRIC: {
    validationType: 1001,
    provider: "ACEPTA"
  },
  EQUIFAX_KBA: {
    validationType: 1002,
    provider: "EQUIFAX"
  },
  SINACOFI_KBA: {
    validationType: 1002,
    provider: "SINACOFI"
  },
  FACIAL_RECOGNITION: {
    validationType: 1003,
    provider: "TOC"
  },
  CICUE: {
    authMethod: "CICUE",
    validationType: 1005,
    provider: "ECERT"
  }
};

class PreviredAPIClient {
  constructor(previredApiUrl, kongService, traceId = undefined) {
    this.kongService = kongService;
    this.previredApiUrl = previredApiUrl;
    this.traceId = traceId;
  }

  async requestConfig() {
    const token = await this.kongService.getToken();
    return {
      headers: {
        //"PFG-Trace-ID": this.traceId,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    };
  }

  async submitPreviredDataRequest(client, executive, validation) {
    const fetchConfig = await this.requestConfig();
    const dataRequest = {
      ticketRequest: {
        client: { ...client, url: client.url.replace("\n", "") },
        validationType: validation.validationType,
        provider: validation.provider
      },
      traceId: this.traceId
    };
    if (executive) {
      dataRequest.ticketRequest.executive = {
        name: executive.name,
        lastName: executive.lastName,
        motherLastName: executive.motherLastName,
        rut: executive.rut,
        auditCode: executive.auditCode,
        url: executive.url.replace("\n", "")
      };
    }
    return fetch(`${this.previredApiUrl}/api/v1/validate/${executive ? "assisted" : "self"}`,
      {
        method: "POST",
        headers: fetchConfig,
        body: JSON.stringify(dataRequest)
      }
    ).then(response => response.json());
  }

  async getPreviredToken(transactionId) {
    const fetchConfig = await this.requestConfig();
    return fetch(`${this.previredApiUrl}/api/v1/token?traceId=${this.traceId}&transactionId=${transactionId}`,
      {
        method: "GET",
        headers: fetchConfig
      }
    ).then(response => response.json());
  }
}

class PreviredAPI {
  constructor(previredApiUrl, kongService, useMock = false, traceId) {
    this.kongService = kongService;
    this.previredAPIClient = useMock
      ? new PreviredAPIClientMock()
      : new PreviredAPIClient(previredApiUrl, this.kongService, traceId);
  }

  // submitData(client, executive, validation) {
  //   return this.previredAPIClient.submitPreviredDataRequest(client, executive, validation);
  // }

  pollingForPreviredData(transactionId) {
    const intervalInMillis = parseInt(process.env.VUE_APP_PREVIRED_POLLING_INTERVAL);
    const errorCounterThreshold = parseInt(process.env.VUE_APP_PREVIRED_POLLING_ERROR_THRESHOLD);
    const maxTime = parseInt(process.env.VUE_APP_PREVIRED_POLLING_MAX_TIME);
    const previredAPI = this;
    return new Promise((resolve, reject) => {
      let errorCounter = 0;
      function poll(startTime) {
        let timePassed = Date.now() - startTime;
        if (timePassed < maxTime) {
          setTimeout(() => {
            previredAPI.previredAPIClient
              .getPreviredToken(transactionId)
              .then(completeResponse => {
                const response = completeResponse.data;
                if (response.status === "OK") {
                  // eslint-disable-next-line
                  if (response.token != null)
                    resolve({
                      transactionId,
                      token: response.token,
                      clientIP: response.clientIP,
                      executiveIP: response.executiveIP,
                      clientProvider: response.clientProvider
                    });
                  else
                    reject(
                      `Fulfillment PreviredAPI returned OK status for transactionId ${transactionId} but no data`
                    );
                } else if (response.status === "PENDING") {
                  poll(startTime);
                } else {
                  reject(
                    `Fulfillment PreviredAPI returned ${response.status} status for transactionId ${transactionId}`
                  );
                }
              })
              .catch(error => {
                errorCounter += 1;
                if (errorCounter >= errorCounterThreshold) {
                  if (error.message) reject(`${error.message}: ${error.reason}`);
                  else
                    reject(
                      `Unknown error calling Fulfillment PreviredAPI: ${JSON.stringify(error)}`
                    );
                } else {
                  poll(startTime);
                }
              });
          }, intervalInMillis);
        } else {
          reject(`Timout (${maxTime}) trying to request previred token`);
        }
      }
      poll(Date.now());
    });
  }

  getPreviredResponse(previredAuthResponse, validation) {
    return new Promise((resolve, reject) => {
      if (previredAuthResponse.status === "OK") {
        return this.previredAPIClient.submitPreviredDataRequest(
          previredAuthResponse.client,
          previredAuthResponse.executive,
          validation
        )
          .then(submitResponse => {
            const transactionId = submitResponse.data.transactionId;
            if (transactionId) {
              this.pollingForPreviredData(transactionId)
                .then(resolve)
                .catch(error => reject(`Error polling from previred-api ${error}`));
            } else {
              reject(
                `No transactionId obtained from data submit in previred-api ${submitResponse}`
              );
            }
          })
          .catch(error => {
            reject(`Error submitting data to previred-api: ${JSON.stringify(error)}`);
          });
      } else {
        reject(`Unexpected previred auth response ${JSON.stringify(previredAuthResponse)}`);
      }
    });
  }
}

export { PreviredAPI, VALIDATION, PREVIRED_MODE };
